// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface AllContactsTabsState {
  // allContactsTab is a value of number type (1-4) and a label of sting type ('maps', 'profiles')
  id: 0 | 1;
  value: number;
  label: 'maps' | 'profiles';
}

const initialState: AllContactsTabsState = {
  id: 0,
  value: 1,
  label: 'maps'
};

const allContactsTabSlice = createSlice({
  name: 'allContactsTab',
  initialState,
  reducers: {
    changeAllContactsTabs: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.label = 'maps';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.label = 'profiles';
      }
      state.value = action.payload;
    }
  }
});

// export the allContactsTab reducer as an action creator
// Redux toolkit automatically generates action creators for
// each reducer function passed into the reducers object
export const { changeAllContactsTabs } = allContactsTabSlice.actions;

// exporting the allContactsTabSlice.reducer function processes the actions
// passed into the redux store, and returns an updated version of the state
// each slice gets its own reducer function that is responsible for updating
// the state for that part of the data
export default allContactsTabSlice.reducer;
