// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface MultiOperationTabsState {
  id: 0 | 1 | 2;
  value: number;
  label: 'summary' | 'analysis' | 'locations';
}

const initialState: MultiOperationTabsState = {
  id: 0,
  value: 1,
  label: 'summary'
};

const multiOperationTabSlice = createSlice({
  name: 'multiOperationTab',
  initialState,
  reducers: {
    changeMultiOperationTabs: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.label = 'summary';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.label = 'analysis';
      } else if (action.payload === 3) {
        state.id = 2;
        state.value = 3;
        state.label = 'locations';
      }
      state.value = action.payload;
    }
  }
});

export const { changeMultiOperationTabs } = multiOperationTabSlice.actions;

export default multiOperationTabSlice.reducer;
