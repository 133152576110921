import { SplineDataObject } from '@/components/dashboard/business-intelligence/multi-operation-analysis/locations-tab/components/multi-operation-locations-spline-chart/multi-operation-locations-spline-chart';

import { createSlice } from '@reduxjs/toolkit';

interface MultiOperationSelectedObject {
  seven_day?: {
    data: {
      current: SplineDataObject[];
      previous: SplineDataObject[];
    };
  };
  four_week?: {
    data: {
      current: SplineDataObject[];
      previous: SplineDataObject[];
    };
  };
  six_month?: {
    data: {
      current: SplineDataObject[];
      previous: SplineDataObject[];
    };
  };
}

export interface MultiOperationsLocationTableRadioSelect {
  selectedMultiOperationsLocationIds: number | null;
  selectedMultiOperationsLocationObjects: MultiOperationSelectedObject;
}

const initialState: MultiOperationsLocationTableRadioSelect = {
  selectedMultiOperationsLocationIds: null,
  selectedMultiOperationsLocationObjects: {}
};

const multiOperationsLocationTableRadioSelectSlice = createSlice({
  name: 'multiOperationsLocationTableRadioSelect',
  initialState,
  reducers: {
    selectMultiOperationsLocationTableRow: (state, action) => {
      const { storeData, store_id } = action.payload;
      state.selectedMultiOperationsLocationIds = store_id;
      state.selectedMultiOperationsLocationObjects = storeData;
    },
    deselectMultiOperationsLocationTableRow: (state) => {
      state.selectedMultiOperationsLocationIds =
        initialState.selectedMultiOperationsLocationIds;
      state.selectedMultiOperationsLocationObjects =
        initialState.selectedMultiOperationsLocationObjects;
    }
  }
});

export const {
  selectMultiOperationsLocationTableRow,
  deselectMultiOperationsLocationTableRow
} = multiOperationsLocationTableRadioSelectSlice.actions;

export default multiOperationsLocationTableRadioSelectSlice.reducer;
