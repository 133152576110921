import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PlanIdState {
  value: string | null;
}

const initialState: PlanIdState = {
  value: null
};

const planIdSlice = createSlice({
  name: 'planId',
  initialState,
  reducers: {
    setPlanId: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setPlanId } = planIdSlice.actions;

export default planIdSlice.reducer;
