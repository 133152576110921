import { AiChatbotInterfaceFormValues } from '@/lib/types/ai-setups';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: AiChatbotInterfaceFormValues = {
  botGreeting: 'Hello! How can I help you today?',
  botPlaceholder: 'Ask a question...',
  displayName: '',
  profilePicture: '',
  chatIcon: '',
  headerBgColor: '',
  headerFontColor: '',
  userMessageColor: '',
  chatBubbleButtonColor: '',
  userMessageFontColor: '',
  chatBubbleButtonIconColor: ''
};

const aiChatbotInterfaceInputsSlice = createSlice({
  name: 'aiChatbotInterfaceInputs',
  initialState,
  reducers: {
    setBotGreeting: (state, action: PayloadAction<string>) => {
      state.botGreeting = action.payload;
    },
    setBotPlaceholder: (state, action: PayloadAction<string>) => {
      state.botPlaceholder = action.payload;
    },
    // Add setters for the new properties
    setDisplayName: (state, action: PayloadAction<string>) => {
      state.displayName = action.payload;
    },
    setProfilePicture: (state, action: PayloadAction<string>) => {
      state.profilePicture = action.payload;
    },
    setChatIcon: (state, action: PayloadAction<string>) => {
      state.chatIcon = action.payload;
    },
    setHeaderBgColor: (state, action: PayloadAction<string>) => {
      state.headerBgColor = action.payload;
    },
    setHeaderFontColor: (state, action: PayloadAction<string>) => {
      state.headerFontColor = action.payload;
    },
    setUserMessageColor: (state, action: PayloadAction<string>) => {
      state.userMessageColor = action.payload;
    },
    setChatBubbleButtonColor: (state, action: PayloadAction<string>) => {
      state.chatBubbleButtonColor = action.payload;
    },
    setUserMessageFontColor: (state, action: PayloadAction<string>) => {
      state.userMessageFontColor = action.payload;
    },
    setChatBubbleButtonIconColor: (state, action: PayloadAction<string>) => {
      state.chatBubbleButtonIconColor = action.payload;
    },
    resetBotGreeting: () => initialState,
    setAllDefaultSettings: (state, action) => {
      state.botGreeting = action.payload.botGreeting;
      state.botPlaceholder = action.payload.botPlaceholder;
      state.displayName = action.payload.displayName;
      state.profilePicture = action.payload.profilePicture;
      state.chatIcon = action.payload.chatIcon;
      state.headerBgColor = action.payload.headerBgColor;
      state.headerFontColor = action.payload.headerFontColor;
      state.userMessageColor = action.payload.userMessageColor;
      state.chatBubbleButtonColor = action.payload.chatBubbleButtonColor;
      state.userMessageFontColor = action.payload.userMessageFontColor;
      state.chatBubbleButtonIconColor =
        action.payload.chatBubbleButtonIconColor;
    }
  }
});

export const {
  setBotGreeting,
  setBotPlaceholder,
  resetBotGreeting,
  setDisplayName,
  setProfilePicture,
  setChatIcon,
  setHeaderBgColor,
  setHeaderFontColor,
  setUserMessageColor,
  setChatBubbleButtonColor,
  setUserMessageFontColor,
  setChatBubbleButtonIconColor,
  setAllDefaultSettings
} = aiChatbotInterfaceInputsSlice.actions;

export default aiChatbotInterfaceInputsSlice.reducer;
