import { SurveyAnswerInterface } from './../../types/survey';
import dayjs from 'dayjs';
// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SurveyInterface } from '@/lib/types/survey';

// import dayjs from 'dayjs';

interface FeedbackState {
  startingListSurveys: SurveyInterface[] | [];
  surveys: SurveyInterface[] | [];
  filteredSurveys: SurveyInterface[] | [];
  formattedSurveyBase: SurveyInterface[] | [];
  startDateFilter: string | null;
  endDateFilter: string | null;
  netPromoterScoreFilter: number | null;
  experienceScoreFilter: number | null;
  productScoreFilter: number | null;
  bookmarkedFilter: string | undefined;
  additionalFeedbackFilter: number | boolean | null;
  surveyTypeFilter: string | undefined;
  needsAttentionFilter: string | undefined;
}

const initialState: FeedbackState = {
  startingListSurveys: [],
  surveys: [],
  filteredSurveys: [],
  formattedSurveyBase: [],
  startDateFilter: null,
  endDateFilter: null,
  netPromoterScoreFilter: null,
  experienceScoreFilter: null,
  productScoreFilter: null,
  needsAttentionFilter: '',
  bookmarkedFilter: '',
  additionalFeedbackFilter: null,
  surveyTypeFilter: ''
};

const filterCustomerFeedbackSlice = createSlice({
  name: 'filterCustomerFeedback',
  initialState,
  reducers: {
    setSurveyBase: (state, action) => {
      state.startingListSurveys = action.payload;
    },
    updateSurveys: (state, action) => {
      state.surveys = action.payload;
    },

    updateFilteredSurveys: (state, action) => {
      state.filteredSurveys = action.payload;
    },

    updateFormattedFilteredSurveyBase: (state, action) => {
      state.formattedSurveyBase = action.payload;
    },

    updateStartDateFilter: (state, action) => {
      state.startDateFilter = action.payload;
      const startDateFilteredSurveys = state.surveys.filter(
        (survey: SurveyInterface) => {
          return dayjs(survey.survey_info.date_answered).isAfter(
            dayjs(state.startDateFilter)
          );
        }
      );
      state.surveys = startDateFilteredSurveys;
      state.formattedSurveyBase = startDateFilteredSurveys.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
      state.filteredSurveys = startDateFilteredSurveys.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
    },

    updateEndDateFilter: (state, action) => {
      state.endDateFilter = action.payload;
      const endDateFilteredSurveys = state.surveys.filter(
        (survey: SurveyInterface) => {
          return dayjs(survey.survey_info.date_answered).isBefore(
            dayjs(state.endDateFilter)
          );
        }
      );
      state.surveys = endDateFilteredSurveys;
      state.formattedSurveyBase = endDateFilteredSurveys.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
      state.filteredSurveys = endDateFilteredSurveys.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
    },

    updateNetPromoterScoreFilter: (state, action) => {
      state.netPromoterScoreFilter = action.payload;

      const filteredStarQuestions = state.surveys.filter(
        (survey: SurveyInterface) => {
          return survey.survey_info.survey_answers.some(
            (answer: SurveyAnswerInterface) => {
              return (
                answer.question.id === 7 && answer.choice == action.payload
              );
            }
          );
        }
      );

      state.filteredSurveys = filteredStarQuestions.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
    },
    updateProductScoreFilter: (state, action) => {
      state.productScoreFilter = action.payload;

      const filteredStarQuestions = state.surveys.filter(
        (survey: SurveyInterface) => {
          return survey.survey_info.survey_answers.some(
            (answer: SurveyAnswerInterface) => {
              return (
                answer.question.id === 5 && answer.choice == action.payload
              );
            }
          );
        }
      );

      state.filteredSurveys = filteredStarQuestions.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
    },
    updateExperienceScoreFilter: (state, action) => {
      state.experienceScoreFilter = action.payload;

      const filteredStarQuestions = state.surveys.filter(
        (survey: SurveyInterface) => {
          return survey.survey_info.survey_answers.some(
            (answer: SurveyAnswerInterface) => {
              return (
                answer.question.id === 3 && answer.choice == action.payload
              );
            }
          );
        }
      );

      state.filteredSurveys = filteredStarQuestions.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
    },

    updateSurveyTypeFilter: (state, action) => {
      state.surveyTypeFilter = action.payload;

      if (action.payload === '1') {
        state.netPromoterScoreFilter = null;
        state.experienceScoreFilter = null;
        state.productScoreFilter = null;
      }

      //if the action.payload is '', then we want to reset the filter
      //while keeping the other filters in place
      if (action.payload === '') {
        state.surveys = state.startingListSurveys;
        state.formattedSurveyBase = state.startingListSurveys.map(
          (survey: SurveyInterface) => {
            return survey;
          }
        );
        state.filteredSurveys = state.startingListSurveys.map(
          (survey: SurveyInterface) => {
            return survey;
          }
        );
        //reset the other filters
        state.netPromoterScoreFilter = null;
        state.experienceScoreFilter = null;
        state.productScoreFilter = null;
        state.startDateFilter = null;
        state.endDateFilter = null;
        return;
      }

      const filterByTypeSurveys = state.surveys.filter(
        (survey: SurveyInterface) => {
          return survey.survey_info.survey_id == action.payload;
        }
      );

      state.filteredSurveys = filterByTypeSurveys.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
    },
    updateBookMarkedFilter: (state, action) => {
      state.bookmarkedFilter = action.payload;

      //if the action.payload is '', then we want to reset the filter
      //while keeping the other filters in place
      if (action.payload === '') {
        state.surveys = state.startingListSurveys;
        state.formattedSurveyBase = state.startingListSurveys.map(
          (survey: SurveyInterface) => {
            return survey;
          }
        );
        state.filteredSurveys = state.startingListSurveys.map(
          (survey: SurveyInterface) => {
            return survey;
          }
        );
        //reset the other filters
        state.netPromoterScoreFilter = null;
        state.experienceScoreFilter = null;
        state.productScoreFilter = null;
        state.startDateFilter = null;
        state.endDateFilter = null;
        return;
      }

      const filterSurveysByBookMarked = state.surveys.filter(
        (survey: SurveyInterface) => {
          return state.bookmarkedFilter === '1'
            ? survey.bookmarked === true
            : state.bookmarkedFilter === '0'
            ? survey.bookmarked === false
            : null;
        }
      );

      state.filteredSurveys = filterSurveysByBookMarked.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
    },

    updateNeedsAttentionFilter: (state, action) => {
      state.needsAttentionFilter = action.payload;

      //if the action.payload is '', then we want to reset the filter
      //while keeping the other filters in place
      if (action.payload === '') {
        state.surveys = state.startingListSurveys;
        state.formattedSurveyBase = state.startingListSurveys.map(
          (survey: SurveyInterface) => {
            return survey;
          }
        );
        state.filteredSurveys = state.startingListSurveys.map(
          (survey: SurveyInterface) => {
            return survey;
          }
        );
        //reset the other filters
        state.netPromoterScoreFilter = null;
        state.experienceScoreFilter = null;
        state.productScoreFilter = null;
        state.startDateFilter = null;
        state.endDateFilter = null;
        return;
      }

      const filterSurveysByNeedsAttention = state.surveys.filter(
        (survey: SurveyInterface) => {
          return state.needsAttentionFilter === '1'
            ? survey.needs_attention === true
            : state.needsAttentionFilter === '0'
            ? survey.needs_attention === false
            : null;
        }
      );

      state.filteredSurveys = filterSurveysByNeedsAttention.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
    },

    resetFilters: (state) => {
      state.netPromoterScoreFilter = null;
      state.experienceScoreFilter = null;
      state.productScoreFilter = null;
      state.startDateFilter = null;
      state.endDateFilter = null;
      state.surveyTypeFilter = '';
      state.bookmarkedFilter = '';
      state.needsAttentionFilter = '';
      state.surveys = state.startingListSurveys;
      state.formattedSurveyBase = state.startingListSurveys.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
      state.filteredSurveys = state.startingListSurveys.map(
        (survey: SurveyInterface) => {
          return survey;
        }
      );
    }
  }
});

export const {
  updateSurveys,
  updateFilteredSurveys,
  updateFormattedFilteredSurveyBase,
  updateStartDateFilter,
  updateEndDateFilter,
  updateNetPromoterScoreFilter,
  updateProductScoreFilter,
  updateExperienceScoreFilter,
  updateSurveyTypeFilter,
  updateNeedsAttentionFilter,
  setSurveyBase,
  resetFilters,
  updateBookMarkedFilter
} = filterCustomerFeedbackSlice.actions;

export default filterCustomerFeedbackSlice.reducer;
