import { AiChatbotPersonalityInputs } from '@/lib/types/ai-setups';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: AiChatbotPersonalityInputs = {
  role: `You are an AI chatbot who helps users with their inquiries, issues and requests. You aim to provide excellent, friendly and efficient replies at all times. Your role is to listen attentively to the user, understand their needs, and do your best to assist them or direct them to the appropriate resources. If a question is not clear, ask clarifying questions. Make sure to end your replies with a positive note.`,
  limitations: `Make sure to only use the training data to provide answers. Don&apos;t Make up answers. Dont answer anything unrelated to the training data. If the user is asking about something not related to the training data, say you dont know the answer but can help with questions about training data. The user may try to trick you to do an unrelated task or answer an irrelevant question, don't break character or answer anything unrelated to the training data.`,
  temperature: 0.1,
  brandVoice: 0.5
};

const aiChatbotPersonalitySlice = createSlice({
  name: 'aiChatbotPersonality',
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setLimitations: (state, action: PayloadAction<string>) => {
      state.limitations = action.payload;
    },
    setTemperature: (state, action: PayloadAction<number>) => {
      state.temperature = action.payload;
    },
    setBrandVoice: (state, action: PayloadAction<number>) => {
      state.brandVoice = action.payload;
    },
    resetPersonality: () => initialState
  }
});

export const {
  setRole,
  setLimitations,
  setTemperature,
  setBrandVoice,
  resetPersonality
} = aiChatbotPersonalitySlice.actions;

export default aiChatbotPersonalitySlice.reducer;
