import { createSlice } from '@reduxjs/toolkit';

export interface ContactProfileSidebarState {
  ai_recommended_products_accordion: 'open' | 'close';
  purchase_statistics_accordion: 'open' | 'close';
  personal_info_accordion: 'open' | 'close';
  marketing_groups_accordion: 'open' | 'close';
  shares_phone_number_accordion: 'open' | 'close';
  shares_email_accordion: 'open' | 'close';
  optimized_customer_journey_accordion: 'open' | 'close';
}

const initialState: ContactProfileSidebarState = {
  ai_recommended_products_accordion: 'open',
  purchase_statistics_accordion: 'open',
  personal_info_accordion: 'open',
  marketing_groups_accordion: 'open',
  shares_phone_number_accordion: 'open',
  shares_email_accordion: 'open',
  optimized_customer_journey_accordion: 'open'
};

const contactProfileSidebarSlice = createSlice({
  name: 'contactProfileSidebar',
  initialState,
  reducers: {
    //** Accordions
    changeContactProfileAiRecommendedProductsAccordion: (state) => {
      state.ai_recommended_products_accordion =
        state.ai_recommended_products_accordion === 'open' ? 'close' : 'open';
    },
    changeContactProfilePurchaseStatisticsAccordion: (state) => {
      state.purchase_statistics_accordion =
        state.purchase_statistics_accordion === 'open' ? 'close' : 'open';
    },
    changeContactProfilePersonalInformationAccordion: (state) => {
      state.personal_info_accordion =
        state.personal_info_accordion === 'open' ? 'close' : 'open';
    },
    changeContactProfileMarketingGroupsAccordion: (state) => {
      state.marketing_groups_accordion =
        state.marketing_groups_accordion === 'open' ? 'close' : 'open';
    },
    changeContactProfileSharesPhoneNumberAccordion: (state) => {
      state.shares_phone_number_accordion =
        state.shares_phone_number_accordion === 'open' ? 'close' : 'open';
    },
    changeContactProfileSharesEmailsAccordion: (state) => {
      state.shares_email_accordion =
        state.shares_email_accordion === 'open' ? 'close' : 'open';
    },
    changeContactProfileOptimizedCustomerJourneyAccordion: (state) => {
      state.optimized_customer_journey_accordion =
        state.optimized_customer_journey_accordion === 'open'
          ? 'close'
          : 'open';
    }
    //** End Accordions
  }
});

export const {
  changeContactProfileAiRecommendedProductsAccordion,
  changeContactProfilePurchaseStatisticsAccordion,
  changeContactProfilePersonalInformationAccordion,
  changeContactProfileMarketingGroupsAccordion,
  changeContactProfileSharesPhoneNumberAccordion,
  changeContactProfileSharesEmailsAccordion,
  changeContactProfileOptimizedCustomerJourneyAccordion
} = contactProfileSidebarSlice.actions;

export default contactProfileSidebarSlice.reducer;
