// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface CustomerFeedbackTabsState {
  // customerFeedbackTab is a value of number type (1-2) and a label of sting type ('summary', 'feedback')
  id: 0 | 1;
  value: number;
  label: 'summary' | 'feedback';
}

const initialState: CustomerFeedbackTabsState = {
  id: 0,
  value: 1,
  label: 'summary'
};

const customerFeedbackTabSlice = createSlice({
  name: 'customerFeedbackTab',
  initialState,
  reducers: {
    changeCustomerFeedbackTabs: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.label = 'summary';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.label = 'feedback';
      }
      state.value = action.payload;
    }
  }
});

// export the customerFeedbackTab reducer as an action creator
// Redux toolkit automatically generates action creators for
// each reducer function passed into the reducers object
export const { changeCustomerFeedbackTabs } = customerFeedbackTabSlice.actions;

// exporting the customerFeedbackTabSlice.reducer function processes the actions
// passed into the redux store, and returns an updated version of the state
// each slice gets its own reducer function that is responsible for updating
// the state for that part of the data
export default customerFeedbackTabSlice.reducer;
