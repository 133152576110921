// import the TypedUseSelectorHook, useSelector and useDispatch hooks from react-redux
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
// import the RootState and AppDispatch types from the store.ts file
import { AppDispatch, RootState } from './store';
// import the createListenerMiddleware and addListener functions from @reduxjs/toolkit
import { createListenerMiddleware, addListener } from '@reduxjs/toolkit';
// import the TypedStartListening and TypedAddListener types from @reduxjs/toolkit
import type { TypedStartListening, TypedAddListener } from '@reduxjs/toolkit';

// The middleware code is fully TS-typed. However, the startListening and addListener functions
// do not know what the store's RootState type looks like by default, so getState() will return
// unknown.To fix this, the middleware provides types for defining "pre-typed" versions of those
// methods, similar to the pattern used for defing pre-typed React-Redux hooks. We specifically
// recommend creating the middleware instance in a separate file from the actual configureStore() call:

export const listenerMiddleware = createListenerMiddleware();
export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

// create a custom hook that returns the AppDispatch type
export const useAppDispatch = () => useDispatch<AppDispatch>();
// create a custom hook that returns the RootState type
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
