import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SettingsAiTrainingManualTabsState {
  id: 0 | 1 | 2 | 3 | 4;
  value: string;
  label:
    | 'products-services'
    | 'about-your-business'
    | 'important-cautions-warnings'
    | 'returns-and-refunds'
    | 'additional-text-information';
  locationTabs: string;
}

const initialState: SettingsAiTrainingManualTabsState = {
  id: 0,
  value: 'products-services',
  label: 'products-services',
  locationTabs: 'products-services'
};

const settingsAiTrainingManualTabsSlice = createSlice({
  name: 'settingsAiTrainingManualTab',
  initialState,
  reducers: {
    changeSettingsAiTrainingManualTabs: (
      state,
      action: PayloadAction<string>
    ) => {
      switch (action.payload) {
        case 'products-services':
          state.id = 0;
          state.value = 'products-services';
          state.label = 'products-services';
          break;
        case 'about-your-business':
          state.id = 1;
          state.value = 'about-your-business';
          state.label = 'about-your-business';
          break;
        case 'important-cautions-warnings':
          state.id = 2;
          state.value = 'important-cautions-warnings';
          state.label = 'important-cautions-warnings';
          break;
        case 'returns-and-refunds':
          state.id = 3;
          state.value = 'returns-and-refunds';
          state.label = 'returns-and-refunds';
          break;
        case 'additional-text-information':
          state.id = 4;
          state.value = 'additional-text-information';
          state.label = 'additional-text-information';
          break;
        default:
          break;
      }
      state.locationTabs = action.payload;
    }
  }
});

export const { changeSettingsAiTrainingManualTabs } =
  settingsAiTrainingManualTabsSlice.actions;

export default settingsAiTrainingManualTabsSlice.reducer;
