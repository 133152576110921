// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface hideTimeframeSelectorState {
  value: boolean;
}

const initialState: hideTimeframeSelectorState = {
  value: false
};

const hideTimeframeSelectorSlice = createSlice({
  name: 'hideTimeframeSelector',
  initialState,
  reducers: {
    hideTimeframeSelector: (state, action) => {
      if (action.payload === true) {
        state.value = true;
      } else if (action.payload === false) state.value = action.payload;
    }
  }
});

export const { hideTimeframeSelector } = hideTimeframeSelectorSlice.actions;

export default hideTimeframeSelectorSlice.reducer;
