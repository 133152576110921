// import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// export interface SettingsIntegrationsTabsState {
//   currentView: string;
// }

// const initialState: SettingsIntegrationsTabsState = {
//   currentView: 'shopify'
// };

// const settingsIntegrationsTabsSlice = createSlice({
//   name: 'settingsIntegrationsTabs',
//   initialState,
//   reducers: {
//     setCurrentView: (state, action: PayloadAction<string>) => {
//       state.currentView = action.payload;
//     }
//   }
// });

// export const { setCurrentView } = settingsIntegrationsTabsSlice.actions;
// export default settingsIntegrationsTabsSlice.reducer;

import { AdjustmentsHorizontalIcon } from '@/assets/icons/adjustments-horizonal-icon';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SettingsIntegrationsTabsState {
  currentView: string;
}

export const views = [
  {
    label: 'Shopify',
    value: 'shopify',
    icon: AdjustmentsHorizontalIcon,
    disabled: false
  }
  // {
  //   label: 'Clover',
  //   value: 'clover',
  //   icon: AdjustmentsHorizontalIcon
  // },
  //   {
  //     label: 'Wordpress',
  //     value: 'wordpress',
  //     icon: AdjustmentsHorizontalIcon
  //   },
  //   {
  //     label: 'Bubble',
  //     value: 'bubble',
  //     icon: AdjustmentsHorizontalIcon
  //   }
];

const initialState: SettingsIntegrationsTabsState = {
  currentView: views[0].value
};

const settingsIntegrationsTabsSlice = createSlice({
  name: 'settingsIntegrationsTabs',
  initialState,
  reducers: {
    setCurrentView: (state, action: PayloadAction<string>) => {
      state.currentView = action.payload;
    }
  }
});

export const { setCurrentView } = settingsIntegrationsTabsSlice.actions;
export default settingsIntegrationsTabsSlice.reducer;
