import { createSlice } from '@reduxjs/toolkit';

interface MarketingCreationData {
  name: string;
  description: string;
}

interface MarketingCreationDataWithOptions extends MarketingCreationData {
  marketing_group_options: MarketingOptionsData;
}

interface MultiSelectGroupData {
  id: number | null;
  name: string;
}

interface MarketingOptionsData {
  ai_smart_groups: MultiSelectGroupData[] | null;
  customers_prospects: MultiSelectGroupData[] | null;
  customers_employees: MultiSelectGroupData[] | null;
  gender: MultiSelectGroupData[] | null;
  product_category: MultiSelectGroupData[] | null;
  product_brand: MultiSelectGroupData[] | null;
  product_name: MultiSelectGroupData[] | null;
  age_range_slider: MarketingGroupSliderComponentData | null;
  days_since_last_purchase: MarketingGroupSliderComponentData | null;
  number_of_visits: MarketingGroupSliderComponentData | null;
  purchase_frequency: MarketingGroupSliderComponentData | null;
  average_spent_per_visit: MarketingGroupSliderComponentData | null;
  total_spent: MarketingGroupSliderComponentData | null;
}

interface MarketingGroupSliderComponentData {
  name: string;
  label: string;
  description: string;
  min: number;
  max: number;
  dollar: boolean;
  max_plus: boolean;
}

const initialState: MarketingCreationDataWithOptions = {
  name: '',
  description: '',
  marketing_group_options: {
    ai_smart_groups: [{ id: null, name: '' }],
    customers_prospects: [{ id: null, name: '' }],
    customers_employees: [{ id: null, name: '' }],
    gender: [{ id: null, name: '' }],
    product_category: [{ id: null, name: '' }],
    product_brand: [{ id: null, name: '' }],
    product_name: [{ id: null, name: '' }],
    age_range_slider: {
      name: 'age_range_slider',
      label: 'Age Range',
      description: 'Min 18 — Max 100+',
      min: 18,
      max: 100,
      dollar: false,
      max_plus: true
    },
    days_since_last_purchase: {
      name: 'days_since_last_purchase',
      label: 'Days Since Last Purchase',
      description: 'Min 0 — Max 500',
      min: 0,
      max: 500,
      dollar: false,
      max_plus: true
    },
    number_of_visits: {
      name: 'number_of_visits',
      label: 'Number of Visits',
      description: 'Min 0 — Max 100+',
      min: 0,
      max: 100,
      dollar: false,
      max_plus: true
    },
    purchase_frequency: {
      name: 'purchase_frequency',
      label: 'Purchase Frequency',
      description: 'Min 0 — Max 100+',
      min: 0,
      max: 100,
      dollar: false,
      max_plus: true
    },
    average_spent_per_visit: {
      name: 'average_spent_per_visit',
      label: 'Average Spent Per Visit',
      description: 'Min 0 — Max $500+',
      min: 0,
      max: 500,
      dollar: true,
      max_plus: true
    },
    total_spent: {
      name: 'total_spent',
      label: 'Total Spent',
      description: 'Min 0 — Max $500+',
      min: 0,
      max: 500,
      dollar: true,
      max_plus: true
    }
  }
};

const marketingGroupsCreationSlice = createSlice({
  name: 'marketingGroupsCreation',
  initialState,
  reducers: {
    setMarketingGroupName(state, action) {
      const { name, description } = action.payload;
      state.name = name;
      state.description = description;
    },
    setMarketingGroupOptions(state, action) {
      const {
        ai_smart_groups,
        customers_prospects,
        customers_employees,
        gender,
        product_category,
        product_brand,
        product_name,
        age_range_slider,
        days_since_last_purchase,
        number_of_visits,
        purchase_frequency,
        average_spent_per_visit,
        total_spent
      } = action.payload;
      state.marketing_group_options.ai_smart_groups = ai_smart_groups;
      state.marketing_group_options.customers_prospects = customers_prospects;
      state.marketing_group_options.customers_employees = customers_employees;
      state.marketing_group_options.gender = gender;
      state.marketing_group_options.product_category = product_category;
      state.marketing_group_options.product_brand = product_brand;
      state.marketing_group_options.product_name = product_name;
      state.marketing_group_options.age_range_slider = age_range_slider;
      state.marketing_group_options.days_since_last_purchase =
        days_since_last_purchase;
      state.marketing_group_options.number_of_visits = number_of_visits;
      state.marketing_group_options.purchase_frequency = purchase_frequency;
      state.marketing_group_options.average_spent_per_visit =
        average_spent_per_visit;
      state.marketing_group_options.total_spent = total_spent;
    }
  }
});

export const { setMarketingGroupName, setMarketingGroupOptions } =
  marketingGroupsCreationSlice.actions;

export default marketingGroupsCreationSlice.reducer;
