// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface EmployeeEngagementTabsState {
  // employeeEngagementTab is a value of number type (1-4) and a label of sting type ('summary', 'feedback')
  id: 0 | 1;
  value: number;
  label: 'summary' | 'feedback';
}

const initialState: EmployeeEngagementTabsState = {
  id: 0,
  value: 1,
  label: 'summary'
};

const employeeEngagementTabSlice = createSlice({
  name: 'employeeEngagementTab',
  initialState,
  reducers: {
    changeEmployeeEngagementTabs: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.label = 'summary';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.label = 'feedback';
      }
      state.value = action.payload;
    }
  }
});

// export the employeeEngagementTab reducer as an action creator
// Redux toolkit automatically generates action creators for
// each reducer function passed into the reducers object
export const { changeEmployeeEngagementTabs } =
  employeeEngagementTabSlice.actions;

// exporting the employeeEngagementTabSlice.reducer function processes the actions
// passed into the redux store, and returns an updated version of the state
// each slice gets its own reducer function that is responsible for updating
// the state for that part of the data
export default employeeEngagementTabSlice.reducer;
