import { ProductPerformancePerformanceActiveProducts } from '@/lib/types/rtk-types/chart-data';
import { createSlice } from '@reduxjs/toolkit';

export interface SelectProductPerformanceAndReconsiderIDsState {
  selectedPerformanceIds: number[];
  selectedReconsiderIds: number[];

  selectedReconsiderObjects: ProductPerformancePerformanceActiveProducts[];
  selectedPerformanceObjects: ProductPerformancePerformanceActiveProducts[];
}

const initialState: SelectProductPerformanceAndReconsiderIDsState = {
  selectedPerformanceIds: [],
  selectedReconsiderIds: [],
  selectedReconsiderObjects: [],
  selectedPerformanceObjects: []
};

const selectProductPerformanceAndReconsiderIDsSlice = createSlice({
  name: 'selectProductPerformanceAndReconsiderIDs',
  initialState,
  reducers: {
    selectPerformanceProductObject: (state, action) => {
      const { object, id } = action.payload;

      if (
        state.selectedPerformanceIds.length < 3 &&
        !state.selectedPerformanceIds.includes(id)
      ) {
        state.selectedPerformanceIds.push(id);
        state.selectedPerformanceObjects.push(object);
      }
    },

    deselectPerformanceProductObject: (state, action) => {
      const { id } = action.payload;
      const index = state.selectedPerformanceIds.indexOf(id);
      if (index !== -1) {
        state.selectedPerformanceIds.splice(index, 1);
        state.selectedPerformanceObjects.splice(index, 1);
      }
    },
    clearSelectedPerformanceProducts: (state) => {
      state.selectedPerformanceIds = [];
      state.selectedPerformanceObjects = [];
    },
    selectReconsiderProduct: (state, action) => {
      const { object, id } = action.payload;
      if (
        state.selectedReconsiderIds.length < 3 &&
        !state.selectedReconsiderIds.includes(id)
      ) {
        state.selectedReconsiderIds.push(id);
        state.selectedReconsiderObjects.push(object);
      }
    },
    deselectReconsiderProduct: (state, action) => {
      const { id } = action.payload;
      const index = state.selectedReconsiderIds.indexOf(id);
      if (index !== -1) {
        state.selectedReconsiderIds.splice(index, 1);
        state.selectedReconsiderObjects.splice(index, 1);
      }
    },
    clearSelectedReconsiderProducts: (state) => {
      state.selectedReconsiderIds = [];
      state.selectedReconsiderObjects = [];
    }
  }
});

export const {
  clearSelectedPerformanceProducts,
  selectReconsiderProduct,
  deselectReconsiderProduct,
  clearSelectedReconsiderProducts,
  selectPerformanceProductObject,
  deselectPerformanceProductObject
} = selectProductPerformanceAndReconsiderIDsSlice.actions;

export default selectProductPerformanceAndReconsiderIDsSlice.reducer;
