import { createSlice } from '@reduxjs/toolkit';

export interface AiRecommendationsState {
  id: 0 | 1 | 2;
  value: number;
  label: 'summary' | 'business-recommendations' | 'customer-recommendations';
}

const initialState: AiRecommendationsState = {
  id: 0,
  value: 1,
  label: 'summary'
};

const AiRecommendationsSlice = createSlice({
  name: 'shoppingTrendsTab',
  initialState,
  reducers: {
    changeAiRecommendationsTabs: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.label = 'summary';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.label = 'business-recommendations';
      } else if (action.payload === 3) {
        state.id = 2;
        state.value = 3;
        state.label = 'customer-recommendations';
      }
      state.value = action.payload;
    }
  }
});

// export the shoppingTrendsTab reducer as an action creator
// Redux toolkit automatically generates action creators for
// each reducer function passed into the reducers object
export const { changeAiRecommendationsTabs } = AiRecommendationsSlice.actions;

// exporting the AiRecommendationslice.reducer function processes the actions
// passed into the redux store, and returns an updated version of the state
// each slice gets its own reducer function that is responsible for updating
// the state for that part of the data
export default AiRecommendationsSlice.reducer;
