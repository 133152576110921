import { AiTrainingTextInputs } from '@/lib/types/ai-setups';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AiTrainingTextInputs = {
  productServices: '',
  aboutBusiness: '',
  cautionsWarnings: '',
  additionalInformation: '',
  lastUserToSign: {
    signed_name: '',
    updated_at: ''
  }
};

const aiTrainingTextInputsSlice = createSlice({
  name: 'aiTrainingTextInputs',
  initialState,
  reducers: {
    setProductServices: (state, action: PayloadAction<string>) => {
      state.productServices = action.payload;
    },
    setAboutBusiness: (state, action: PayloadAction<string>) => {
      state.aboutBusiness = action.payload;
    },
    setCautionsWarnings: (state, action: PayloadAction<string>) => {
      state.cautionsWarnings = action.payload;
    },
    setAdditionalInformation: (state, action: PayloadAction<string>) => {
      state.additionalInformation = action.payload;
    },
    setLastUserToSign: (
      state,
      action: PayloadAction<{ signed_name: string; updated_at: string }>
    ) => {
      state.lastUserToSign = {
        signed_name: action.payload.signed_name,
        updated_at: action.payload.updated_at
      };
    },
    setAllVectorSettings: (state, action) => {
      state.productServices = action.payload.productServices;
      state.aboutBusiness = action.payload.aboutBusiness;
      state.cautionsWarnings = action.payload.cautionsWarnings;
      state.additionalInformation = action.payload.additionalInformation;
      state.lastUserToSign = {
        signed_name: action.payload.lastUserToSign.signed_name,
        updated_at: action.payload.lastUserToSign.updated_at
      };
    }
  }
});

export const {
  setProductServices,
  setAboutBusiness,
  setCautionsWarnings,
  setAdditionalInformation,
  setLastUserToSign,
  setAllVectorSettings
} = aiTrainingTextInputsSlice.actions;

export default aiTrainingTextInputsSlice.reducer;
