import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileTabsState {
  currentView: string;
  completedTabs: string[];
}

const initialState: ProfileTabsState = {
  currentView: '',
  completedTabs: []
};

const profileTabsSlice = createSlice({
  name: 'profileTabs',
  initialState,
  reducers: {
    setInitialView: (state, action: PayloadAction<string>) => {
      if (state.currentView === '') {
        state.currentView = action.payload;
      }
    },
    setCurrentView: (state, action: PayloadAction<string>) => {
      state.currentView = action.payload;
    },

    markTabAsCompleted: (state, action: PayloadAction<string>) => {
      if (
        state.completedTabs &&
        !state.completedTabs.includes(action.payload)
      ) {
        state.completedTabs.push(action.payload);
      }
    }
  }
});

export const { setCurrentView, setInitialView, markTabAsCompleted } =
  profileTabsSlice.actions;
export default profileTabsSlice.reducer;
