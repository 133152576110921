import { onboardingSteps } from '@/lib/utils/onboardingSteps';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OnboardingCompletedOnboardingTabsState {
  completedOnboardingTabs: string[];
  completedAiTrainingTabs: string[];
  currentStep: string;
}

const initialState: OnboardingCompletedOnboardingTabsState = {
  completedOnboardingTabs: [
    'marketing-branding',
    'ai-communication-preferences'
  ],
  completedAiTrainingTabs: ['manual-training', 'products-services'],
  currentStep: onboardingSteps[0]
};

const completedOnboardingTabsSlice = createSlice({
  name: 'completedOnboardingTabs',
  initialState,
  reducers: {
    markOnboardingTabAsCompleted: (state, action: PayloadAction<string>) => {
      const completedTab = action.payload;
      if (!state.completedOnboardingTabs?.includes(completedTab)) {
        state.completedOnboardingTabs.push(completedTab);
      }
      const currentIndex = onboardingSteps.indexOf(completedTab);
      if (currentIndex < onboardingSteps.length - 1) {
        state.currentStep = onboardingSteps[currentIndex + 1];
      }
    },
    markAiTrainingTabAsCompleted: (state, action: PayloadAction<string>) => {
      const completedTab = action.payload;
      if (!state.completedAiTrainingTabs?.includes(completedTab)) {
        state.completedAiTrainingTabs.push(completedTab);
      }
    }
  }
});

export const { markOnboardingTabAsCompleted, markAiTrainingTabAsCompleted } =
  completedOnboardingTabsSlice.actions;
export default completedOnboardingTabsSlice.reducer;
