import { createSlice } from '@reduxjs/toolkit';
export interface ProspectManagementTabsState {
  id: 0 | 1 | 2;
  value: number;
  label: 'summary' | 'opportunities' | 'prospects';
  openModal: boolean;
  openModalType: 'create' | 'edit' | null;
  modalTitle: 'Prospect' | 'Contact';
}
const initialState: ProspectManagementTabsState = {
  id: 0,
  value: 1,
  label: 'summary',
  openModal: false,
  openModalType: null,
  modalTitle: 'Prospect'
};
const prospectManagementTabsSlice = createSlice({
  name: 'prospectManagement',
  initialState,
  reducers: {
    changeProspectManagementTabs: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.label = 'summary';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.label = 'opportunities';
      } else if (action.payload === 3) {
        state.id = 2;
        state.value = 3;
        state.label = 'prospects';
      }
      state.value = action.payload;
    },
    toggleCreateProspectModal: (state, action) => {
      state.openModal = action.payload;
      state.openModalType = 'create';
    },
    toggleEditProspectModal: (state, action) => {
      state.openModal = action.payload;
      state.openModalType = 'edit';
    },
    updateModalTitle: (state, action) => {
      state.modalTitle = action.payload;
    }
  }
});

export const {
  changeProspectManagementTabs,
  toggleCreateProspectModal,
  toggleEditProspectModal,
  updateModalTitle
} = prospectManagementTabsSlice.actions;
export default prospectManagementTabsSlice.reducer;
