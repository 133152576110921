import {
  MarketingSourceBarChartData,
  MarketingSourceOption
} from '@/lib/types/rtk-types/chart-data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedObjectProps {
  object: MarketingSourceBarChartData;
  selectedMarketingSource: MarketingSourceOption;
}

// Define the initial state for the slice
const initialState: {
  selectedMarketingSources: MarketingSourceOption[];
  marketingSourceOptions: MarketingSourceOption[];
  selectedMarketingObjects: MarketingSourceBarChartData[];
} = {
  selectedMarketingSources: [],
  selectedMarketingObjects: [],
  marketingSourceOptions: []
};

// Create the slice
const marketingSourcesSlice = createSlice({
  name: 'marketingSources',
  initialState,
  reducers: {
    selectMarketingObjects(state, action: PayloadAction<SelectedObjectProps>) {
      const { object, selectedMarketingSource } = action.payload;
      const selectedMarketingObjects = state.selectedMarketingObjects;
      const selectedMarketingSources = state.selectedMarketingSources;
      const updatedMarketingSourceOptions = state.marketingSourceOptions.map(
        (option) =>
          option.id === selectedMarketingSource.id
            ? { ...option, checked: !option.checked }
            : option
      );
      const index = selectedMarketingSources.findIndex(
        (obj) =>
          obj.id === selectedMarketingSource.id &&
          obj.name === selectedMarketingSource.name
      );

      state.marketingSourceOptions = updatedMarketingSourceOptions;
      if (
        selectedMarketingSources.length <= 3 &&
        !selectedMarketingSource.checked
      ) {
        selectedMarketingSources.push(selectedMarketingSource);
        selectedMarketingObjects.push(object);
      } else {
        selectedMarketingSources.splice(index, 1);
        selectedMarketingObjects.splice(index, 1);
      }
    },
    setMarketingSourceOptions(
      state,
      action: PayloadAction<MarketingSourceOption[]>
    ) {
      const updatedMarketingSourceOptions = action.payload;

      state.marketingSourceOptions = updatedMarketingSourceOptions;
    }
  }
});

// Export action creators
export const { selectMarketingObjects, setMarketingSourceOptions } =
  marketingSourcesSlice.actions;

// Export the reducer
export default marketingSourcesSlice.reducer;
