import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProductFormData {
  id?: number;
  productName?: string;
  brandName?: string;
  imageUrl?: string;
  productDescription?: string;
  unitPriceInCents?: number;
  isPurchasable?: boolean;
  isActive?: boolean;
  category?: string;
  weightPerUnit?: string | null;
  dataSourceId?: number | null;
}

export interface ProductFormState {
  productFormData: ProductFormData | null;
  modalType: 'create' | 'edit' | null;
}

const initialState: ProductFormState = {
  productFormData: null,
  modalType: null
};

export const productFormSlice = createSlice({
  name: 'productForm',
  initialState,
  reducers: {
    setProductFormData: (
      state,
      action: PayloadAction<ProductFormData | null>
    ) => {
      if (action.payload === null || action.payload === undefined) {
        state.productFormData = null;
      } else {
        state.productFormData = {
          ...action.payload,
          unitPriceInCents: action.payload.unitPriceInCents
            ? action.payload.unitPriceInCents / 100
            : undefined
        };
      }
    },
    clearProductFormData: (state) => {
      state.productFormData = null;
    },
    setModalType: (state, action: PayloadAction<'create' | 'edit' | null>) => {
      state.modalType = action.payload;
    }
  }
});

export const { setProductFormData, clearProductFormData, setModalType } =
  productFormSlice.actions;

export default productFormSlice.reducer;
