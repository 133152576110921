import { createSlice } from '@reduxjs/toolkit';

export interface selectContactsIdState {
  selectedContactIds: number[];
  refetchProspectsTable: boolean;
  refetchContactsTable: boolean;
  failedContactIds: number[];
}

const initialState: selectContactsIdState = {
  selectedContactIds: [],
  refetchProspectsTable: false,
  refetchContactsTable: false,
  failedContactIds: []
};

const selectContactsIdSlice = createSlice({
  name: 'selectContactsId',
  initialState,
  reducers: {
    selectContacts: (state, action) => {
      const { id } = action.payload;
      if (state.selectedContactIds === null) {
        state.selectedContactIds = [id];
      } else {
        if (state.selectedContactIds.includes(id)) {
          state.selectedContactIds = state.selectedContactIds.filter(
            (prospectId) => prospectId !== id
          );
        } else {
          state.selectedContactIds.push(id);
        }
      }
    },
    clearSelectedContacts: (state) => {
      state.selectedContactIds = [];
    },
    setRefetchProspectsTable: (state, action) => {
      state.refetchProspectsTable = action.payload;
    },
    setRefetchContactsTable: (state, action) => {
      state.refetchContactsTable = action.payload;
    },
    setFailedContactIds: (state, action) => {
      state.failedContactIds = action.payload;
    },
    resetFailedContactIds: (state) => {
      state.failedContactIds = [];
    }
  }
});

export const {
  selectContacts,
  clearSelectedContacts,
  setRefetchProspectsTable,
  setRefetchContactsTable,
  setFailedContactIds,
  resetFailedContactIds
} = selectContactsIdSlice.actions;

export default selectContactsIdSlice.reducer;
