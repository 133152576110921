import {
  combineReducers,
  configureStore,
  ConfigureStoreOptions
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// RTK Query Import
import { api } from './services/api';

// AppThunk Imports
import type { AnyAction } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';
import thunk from 'redux-thunk';

// import each individual reducer from the slices folder
import authReducer from '@/lib/state/slices/auth-slice';

import { reducer as communicationCenterReducer } from '@/lib/state/slices/communication-center-slice';
import calendarReducer from '@/lib/state/slices/calendar-slice';
import notificationsReducer from '@/lib/state/slices/calendar-notification-slice';
import communicationReducer from '@/lib/state/slices/communication-slice';
import hideTimeframeSelectorReducer from '@/lib/state/slices/hide-timeframe-selector-slice';
import marketingSourcesReducer from '@/lib/state/slices/marketing-source-slice';
import userPermissionsReducer from '@/lib/state/slices/user-permissions-slice';
import callCenterReducer from '@/lib/state/slices/call-center-slice';
// Filters
import filterCustomerFeedbackReducer from '@/lib/state/slices/filter-customer-feedback-slice';
import filterCommunicationTheaterContactListReducer from '@/lib/state/slices/filter-communication-theater-contact-list-slice';
import filterTimelineItemsReducer from '@/lib/state/slices/filter-timeline-items-slice';
import timeframeReducer from '@/lib/state/slices/timeframe-slice';
import campaignsSelectorReducer from '@/lib/state/slices/campaigns-selector-slice';
import shoppingTrendsSplineReducer from '@/lib/state/slices/shopping-trends-spline-selector-slice';

// Selectors
import selectProductPerformanceAndReconsiderIDsReducer from '@/lib/state/slices/select-product-performance-and-reconsider-ids-slice';
import selectMarketingAppraisalSourceIDReducer from '@/lib/state/slices/select-marketing-appraisal-source-slice';
import selectMultiLocationStoreIdSlice from '@/lib/state/slices/select-multi-location-store-id-slice';
import contactProfileSidebarReducer from '@/lib/state/slices/contact-profile-sidebar-slice';
import selectMultiOperationAnalysisIdsAndObjectsReducer from '@/lib/state/slices/select-multi-operation-analysis-ids-and-objects-slice';
import multiOperationsLocationTableRadioSelectReducer from '@/lib/state/slices/multi-operations-location-table-radio-select-slice';
import selectContactsIdReducer from '@/lib/state/slices/select-prospects-id-slice';
import selectCommunicationVolumeSplineDataReducer from '@/lib/state/slices/select-communication-volume-spline-data-slice';
// Tabs
import allContactsTabReducer from '@/lib/state/slices/page-tabs-views/all-contacts-tabs-slice';
import customerBehaviorsTabsReducer from '@/lib/state/slices/page-tabs-views/customer-behaviors-tabs-slice';
import customerFeedbackTabsReducer from '@/lib/state/slices/page-tabs-views/customer-feedback-tabs-slice';
import employeeEngagementTabsReducer from '@/lib/state/slices/page-tabs-views/employee-engagement-tabs-slice';
import marketingAppraisalTabsReducer from '@/lib/state/slices/page-tabs-views/marketing-appraisal-tabs-slice';
import marketingCampaignsTabsReducer from '@/lib/state/slices/page-tabs-views/marketing-campaigns-tabs-slice';
import landingPagesTabsReducer from '@/lib/state/slices/page-tabs-views/landing-pages-tabs-slice';
import specialsNarrativeTabsReducer from '@/lib/state/slices/page-tabs-views/specials-narrative-tabs-slice';
import marketingGroupsTabsReducer from '@/lib/state/slices/page-tabs-views/marketing-groups-tabs-slice';
import multiOperationTabsReducer from '@/lib/state/slices/page-tabs-views/multi-operation-tabs-slice';
import productPerformanceTabReducer from '@/lib/state/slices/page-tabs-views/product-performance-tabs-slice';
import prospectManagementTabsReducer from '@/lib/state/slices/page-tabs-views/prospect-management-tabs-slice';
import shoppingTrendsTabsReducer from '@/lib/state/slices/page-tabs-views/shopping-trends-tabs-slice';
import communicationVolumeTabSlice from '@/lib/state/slices/page-tabs-views/communication-volume-tabs-slice';
import marketingGroupsCreationSlice from '@/lib/state/slices/marketing-groups-creation-slice';
import displayMessagingDashboardContainerReducer from '@/lib/state/slices/display-messaging-dashboard-container-slice';
import aiRecommendationsTabsReducer from '@/lib/state/slices/page-tabs-views/ai-recommendations-tabs-slice';
import productFormReducer from '@/lib/state/slices/product-form-slice';
import settingsAiTrainingManualTabsReducer from '@/lib/state/slices/page-tabs-views/settings-ai-training-manual-tabs-slice';
import profileTabsReducer from '@/lib/state/slices/page-tabs-views/settings-profile-tabs-slice';
import settingsAiChatbotTabsReducer from '@/lib/state/slices/page-tabs-views/settings-ai-chatbot-tabs-slice';
import settingsAiTrainingTabsReducer from '@/lib/state/slices/page-tabs-views/settings-ai-training-tabs-slice';
import settingsAiSetupTabsReducer from '@/lib/state/slices/page-tabs-views/settings-ai-setup-tabs-slice';
import completedOnboardingTabsReducer from '@/lib/state/slices/page-tabs-views/completed-onboarding-tabs-slice';
import prospectFormReducer from '@/lib/state/slices/prospect-form-slice';
import settingsTabReducer from '@/lib/state/slices/page-tabs-views/settings-tabs-slice';
import aiTrainingTextInputsReducer from '@/lib/state/slices/ai-training-text-inputs-slice';
import aiChatbotInterfaceInputsReducer from '@/lib/state/slices/ai-chatbot-interface-inputs-slice';
import aiChatbotPersonalityInputsReducer from '@/lib/state/slices/ai-chatbot-personality-inputs-slice';
import settingsIntegrationsTabsReducer from '@/lib/state/slices/page-tabs-views/settings-integrations-tabs-slice';
import planIdReducer from '@/lib/state/slices/plan-id-slice';

//charts
import salesSplineReducer from '@/lib/state/slices/sales-spline-slice';

//Hooks
import { listenerMiddleware, startAppListening } from './hooks';

//prospect reducer

// test COUNTER - pusher
import counterReducer from '@/lib/state/slices/notification-bell-slice';
import logger from '@/lib/state/slices/logger';

// Add one or more listener entries that look for specific actions. They may contain any sync or async logic, similar to thunks.
// This listener will fire whenever the `campaignSelector` action is dispatched
startAppListening({
  predicate: (action, currentState, prevState) =>
    currentState.campaignsSelector.value !== prevState.campaignsSelector.value,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(
      api.util.invalidateTags(['EnhancedCampaignsInitiatedCampaignsData'])
    );
  }
});

// this is where you would change what storage the persisted state is saved to currently it is local storage
const persistConfig = {
  key: 'root',
  storage,
  // if needed will completely overwrite the previous state
  // stateReconciler: hardSet

  // The blacklist and whitelist properties take an array of strings. Each string must match a part of the state that is managed by the reducer we pass to persistReduce
  // blacklist property, we can specify which part of state not to persist, while the whitelist property does the opposite

  blacklist: ['auth', 'callCenter']
  // whitelist: ['users']
};
// combine reducers method combines all reducers into a single variable which we pass to the persistReducer method below
const rootReducer = combineReducers({
  // combine all the individual reducers into a single object so it can act as one data store

  // RTK Query API reducer from src\lib\state\services\api.ts
  [api.reducerPath]: api.reducer,

  // Auth
  auth: authReducer,
  userPermissions: userPermissionsReducer,

  // Pages
  calendar: calendarReducer, // calendar page
  notifications: notificationsReducer, // notifications page
  communication: communicationReducer, // communication page
  communicationCenter: communicationCenterReducer, // old communication center page, remove after BI overview page is complete
  marketingSources: marketingSourcesReducer,
  callCenter: callCenterReducer,
  //Filters
  filterCustomerFeedback: filterCustomerFeedbackReducer,
  filterTimelineItems: filterTimelineItemsReducer,
  filterCommunicationTheaterContactList:
    filterCommunicationTheaterContactListReducer,
  displayMessagingDashboardContainer: displayMessagingDashboardContainerReducer,

  // Selectors

  campaignsSelector: campaignsSelectorReducer,
  contactProfileSidebar: contactProfileSidebarReducer,
  shoppingTrendsSplineSelector: shoppingTrendsSplineReducer,
  selectProductPerformanceAndReconsiderIDs:
    selectProductPerformanceAndReconsiderIDsReducer,
  selectMarketingAppraisalSourceID: selectMarketingAppraisalSourceIDReducer,
  timeframe: timeframeReducer,
  hideTimeframeSelector: hideTimeframeSelectorReducer,
  selectMultiLocationStoreId: selectMultiLocationStoreIdSlice,
  selectMultiOperationAnalysisIdsAndObjects:
    selectMultiOperationAnalysisIdsAndObjectsReducer,
  multiOperationsLocationTableRadioSelect:
    multiOperationsLocationTableRadioSelectReducer,
  prospectsIdSelect: selectContactsIdReducer,
  selectCommunicationVolumeSplineData:
    selectCommunicationVolumeSplineDataReducer,

  // Tabs
  allContactsTab: allContactsTabReducer,
  customerBehaviorsTab: customerBehaviorsTabsReducer,
  customerFeedbackTab: customerFeedbackTabsReducer,
  employeeEngagementTab: employeeEngagementTabsReducer,
  marketingCampaignsTab: marketingCampaignsTabsReducer,
  marketingGroupsTab: marketingGroupsTabsReducer,
  marketingGroupsCreation: marketingGroupsCreationSlice,
  marketingAppraisalTab: marketingAppraisalTabsReducer,
  multiOperationTab: multiOperationTabsReducer,
  productPerformanceTab: productPerformanceTabReducer,
  prospectManagementTab: prospectManagementTabsReducer,
  shoppingTrendsTab: shoppingTrendsTabsReducer,
  landingPagesTab: landingPagesTabsReducer,
  specialsNarrativeTab: specialsNarrativeTabsReducer,
  settingsTab: settingsTabReducer,
  aiRecommendationsTab: aiRecommendationsTabsReducer,
  communicationVolumeTab: communicationVolumeTabSlice,
  settingsAiTrainingManualTab: settingsAiTrainingManualTabsReducer,
  profileTabs: profileTabsReducer,
  settingsAiSetupTabs: settingsAiSetupTabsReducer,
  settingsAiChatbotTabs: settingsAiChatbotTabsReducer,
  settingsAiTrainingTabs: settingsAiTrainingTabsReducer,
  settingsIntegrationsTabs: settingsIntegrationsTabsReducer,
  completedOnboardingTabs: completedOnboardingTabsReducer,

  //charts
  setSalesSpline: salesSplineReducer,

  // test counter slice
  counter: counterReducer,

  //reducer for prospect form
  prospectForm: prospectFormReducer,

  // product form reducer
  productForm: productFormReducer,

  // AI Training
  aiTrainingTextInputs: aiTrainingTextInputsReducer,
  aiChatbotInterfaceInputs: aiChatbotInterfaceInputsReducer,
  aiChatbotPersonalityInputs: aiChatbotPersonalityInputsReducer,

  planId: planIdReducer
});

// this is persistedReducer is what we later use as the reducers in the configureStore below
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const createStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined
) =>
  configureStore({
    // redux devtools extension is only available in development mode
    devTools: process.env.NODE_ENV === 'development',
    // combine all the individual reducers into a single object
    reducer: persistedReducer,

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .prepend(listenerMiddleware.middleware)
        .concat(api.middleware)
        .concat([thunk, logger]),

    ...options
  });

export const store = createStore();
export const persistor = persistStore(store);
// Export the type of the store dispatch actions into the redux store
export type AppDispatch = typeof store.dispatch;

// Export the type of the store state. ReturnType is a TypeScript utility type that transforms
// the type definition of a function into the type of its return value so RootState contains
// the type definition that matches all the data in the redux store
export type RootState = ReturnType<typeof store.getState>;

// Export the AppThunk type for use in slices
export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>;
