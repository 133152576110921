import { createSlice } from '@reduxjs/toolkit';

export interface SettingsAiSetupTabsState {
  currentView:
    | 'ai-communication-preferences'
    | 'ai-personality'
    // | 'document-uploader'
    // | 'text-knowledge-base'
    | 'ai-trainer'
    | 'style'
    | 'test-ai-customer-service-agent';
}

const initialState: SettingsAiSetupTabsState = {
  currentView: 'ai-communication-preferences'
};

const settingsAiSetupTabsSlice = createSlice({
  name: 'settingsAiSetupTabs',
  initialState,
  reducers: {
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    }
  }
});

export const { setCurrentView } = settingsAiSetupTabsSlice.actions;
export default settingsAiSetupTabsSlice.reducer;
