// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface LandingPagesTabsState {
  id: 0 | 1;
  value: number;
  label: 'landing-pages' | 'crate-new-landing-page';
}

const initialState: LandingPagesTabsState = {
  id: 0,
  value: 1,
  label: 'landing-pages'
};

const landingPagesTabSlice = createSlice({
  name: 'landingPagesTab',
  initialState,
  reducers: {
    changeLandingPagesTabs: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.label = 'landing-pages';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.label = 'crate-new-landing-page';
      }
      state.value = action.payload;
    }
  }
});

// export the landingPagesTab reducer as an action creator
// Redux toolkit automatically generates action creators for
// each reducer function passed into the reducers object
export const { changeLandingPagesTabs } = landingPagesTabSlice.actions;

// exporting the landingPagesTabSlice.reducer function processes the actions
// passed into the redux store, and returns an updated version of the state
// each slice gets its own reducer function that is responsible for updating
// the state for that part of the data
export default landingPagesTabSlice.reducer;
