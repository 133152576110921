import { createSlice } from '@reduxjs/toolkit';

export interface UserPermissionsInitialState {
  can_access_bi_comms: false;
  can_access_campaigns: false;
  can_access_communications: false;
  can_access_customers: false;
  can_access_employees: false;
  can_access_feedback: false;
  can_access_locations: false;
  can_access_marketing: false;
  can_access_marketing_groups: false;
  can_access_performance: false;
  can_access_users: false;
  entrance_url: string | null;
  identity: string | null;
  is_ripemetrics: false;
  multi_location: false;
  owner: false;
  token: string | null;
  welcome_modal: false;
}

const initialState: UserPermissionsInitialState = {
  can_access_bi_comms: false,
  can_access_campaigns: false,
  can_access_communications: false,
  can_access_customers: false,
  can_access_employees: false,
  can_access_feedback: false,
  can_access_locations: false,
  can_access_marketing: false,
  can_access_marketing_groups: false,
  can_access_performance: false,
  can_access_users: false,
  entrance_url: null,
  identity: null,
  is_ripemetrics: false,
  multi_location: false,
  owner: false,
  token: null,
  welcome_modal: false
};

const userPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {
    updateUserPermissions: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    updateWelcomeModalShow: (state, action) => {
      return {
        ...state,
        welcome_modal: action.payload
      };
    }
  }
});

export const { updateUserPermissions, updateWelcomeModalShow } =
  userPermissionsSlice.actions;

export default userPermissionsSlice.reducer;
