export const onboardingSteps = [
  'address',
  'branding-marketing',
  'marketing-channels',
  'social-media',
  'hours',
  'terms-privacy',
  'customer-loyalty-page',
  'ai-communication-preferences',
  'document-uploader',
  'text-knowledge-base',
  'test-ai-customer-service-agent'
];
