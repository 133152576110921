import { ClipboardDocumentIcon } from '@/assets/icons/clipboard-document-icon';
import { PaintBrushIcon } from '@/assets/icons/paint-brush-icon';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SettingsAiChatbotTabsState {
  currentView: string;
}

export const views = [
  { label: 'Style', value: 'style', icon: PaintBrushIcon, disabled: false },
  {
    label: 'Embed Chatbot',
    value: 'embed',
    disabled: false,
    icon: ClipboardDocumentIcon
  }
];

const initialState: SettingsAiChatbotTabsState = {
  currentView: views[0].value
};

const settingsAiChatbotTabsSlice = createSlice({
  name: 'settingsAiChatbotTabs',
  initialState,
  reducers: {
    setCurrentView: (state, action: PayloadAction<string>) => {
      state.currentView = action.payload;
    }
  }
});

export const { setCurrentView } = settingsAiChatbotTabsSlice.actions;
export default settingsAiChatbotTabsSlice.reducer;
