import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SettingsAiTrainingTabsState {
  id: 0 | 1 | 2 | 3;
  value:
    | 'manual-training'
    | 'document-training'
    | 'website-training'
    | 'conversation-training';
  label:
    | 'AI Researcher'
    | 'Document Training'
    | 'Website Training'
    | 'Conversation Training';
  locationTabs: string;
}

const initialState: SettingsAiTrainingTabsState = {
  id: 0,
  value: 'manual-training',
  label: 'AI Researcher',
  locationTabs: 'manual-training'
};

const settingsAiTrainingTabsSlice = createSlice({
  name: 'settingsAiTrainingTabs',
  initialState,
  reducers: {
    changeSettingsAiTrainingTabs: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case 'manual-training':
          state.id = 0;
          state.value = 'manual-training';
          state.label = 'AI Researcher';
          break;
        case 'document-training':
          state.id = 1;
          state.value = 'document-training';
          state.label = 'Document Training';
          break;
        case 'website-training':
          state.id = 2;
          state.value = 'website-training';
          state.label = 'Website Training';
          break;

        case 'conversation-training':
          state.id = 3;
          state.value = 'conversation-training';
          state.label = 'Conversation Training';
          break;
        default:
          break;
      }
      state.locationTabs = action.payload;
    }
  }
});

export const { changeSettingsAiTrainingTabs } =
  settingsAiTrainingTabsSlice.actions;

export default settingsAiTrainingTabsSlice.reducer;
