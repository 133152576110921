import { MultiLocationAnalysisActiveProductInterface } from '@/lib/types/rtk-types/chart-data';
import { createSlice } from '@reduxjs/toolkit';

export interface SelectMultiOperationAnalysisIdsAndObjectsState {
  selectedMultiLocationStoreId: string[];
  selectedMultiLocationStoreObjects: MultiLocationAnalysisActiveProductInterface[];
}

const initialState: SelectMultiOperationAnalysisIdsAndObjectsState = {
  selectedMultiLocationStoreId: [],
  selectedMultiLocationStoreObjects: []
};

const SelectMultiOperationAnalysisIdsAndObjectsSlice = createSlice({
  name: 'SelectMultiOperationAnalysisIdsAndObjects',
  initialState,
  reducers: {
    selectMultiLocationObject: (state, action) => {
      const { object, id } = action.payload;

      if (
        state.selectedMultiLocationStoreId.length < 3 &&
        !state.selectedMultiLocationStoreId.includes(id)
      ) {
        state.selectedMultiLocationStoreId =
          typeof id === 'string' ? id.split(',') : id;
        state.selectedMultiLocationStoreObjects.push(object[0]);
      }
    },

    deselectMultiLocationObject: (state, action) => {
      const { arrayOfIds } = action.payload;

      state.selectedMultiLocationStoreId = arrayOfIds;
      state.selectedMultiLocationStoreObjects =
        state.selectedMultiLocationStoreObjects.filter((value) => {
          return arrayOfIds.includes(value?.store_id);
        });
    },
    clearSelectedMultiLocations: (state) => {
      state.selectedMultiLocationStoreId = [];
      state.selectedMultiLocationStoreObjects = [];
    }
  }
});

export const {
  selectMultiLocationObject,
  deselectMultiLocationObject,
  clearSelectedMultiLocations
} = SelectMultiOperationAnalysisIdsAndObjectsSlice.actions;

export default SelectMultiOperationAnalysisIdsAndObjectsSlice.reducer;
