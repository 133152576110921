import { createSlice } from '@reduxjs/toolkit';

export interface SelectMarketingAppraisalSourceIDState {
  selectedMarketingSourceID: number[];
}

const initialState: SelectMarketingAppraisalSourceIDState = {
  selectedMarketingSourceID: []
};

const selectMarketingAppraisalSourceIDSlice = createSlice({
  name: 'selectMarketingAppraisalSourceID',
  initialState,
  reducers: {
    selectMarketingSource: (state, action) => {
      const { id } = action.payload;
      state.selectedMarketingSourceID.push(id);
    },
    deselectMarketingSource: (state, action) => {
      const { id } = action.payload;
      const index = state.selectedMarketingSourceID.indexOf(id);
      if (index !== -1) {
        state.selectedMarketingSourceID.splice(index, 1);
      }
    },
    clearSelectedMarketingSource: (state) => {
      state.selectedMarketingSourceID = [];
    }
  }
});

export const {
  selectMarketingSource,
  clearSelectedMarketingSource,
  deselectMarketingSource
} = selectMarketingAppraisalSourceIDSlice.actions;

export default selectMarketingAppraisalSourceIDSlice.reducer;
