import { createSlice } from '@reduxjs/toolkit';

export interface SalesSplineState {
  salesSplineOptions: any;
}

const initialState: SalesSplineState = {
  salesSplineOptions: null
};

const salesSplineSlice = createSlice({
  name: 'salesSpline',
  initialState,
  reducers: {
    setSalesSplineOptions: (state, action) => {
      state.salesSplineOptions = action.payload;
    }
  }
});

export const { setSalesSplineOptions } = salesSplineSlice.actions;

export default salesSplineSlice.reducer;
