import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum DisplayMessagingDashboardContainerId {
  HIDE = 0,
  DISPLAY = 1
}

export type DisplayMessagingDashboardContainerState = {
  id: DisplayMessagingDashboardContainerId;
  value: 'hide' | 'display';
};

const initialState: DisplayMessagingDashboardContainerState = {
  id: DisplayMessagingDashboardContainerId.HIDE,
  value: 'display'
};

function updateValue(id: DisplayMessagingDashboardContainerId) {
  return id === DisplayMessagingDashboardContainerId.HIDE ? 'hide' : 'display';
}

const displayMessagingDashboardContainerSlice = createSlice({
  name: 'displayMessagingDashboardContainer',
  initialState,
  reducers: {
    changeDisplayMessagingDashboardContainer(
      state,
      action: PayloadAction<DisplayMessagingDashboardContainerId>
    ) {
      state.id = action.payload;
      state.value = updateValue(action.payload);
    },
    resetDisplayMessagingDashboardContainer(state) {
      state.id = DisplayMessagingDashboardContainerId.HIDE;
      state.value = 'hide';
    },
    toggleDisplayMessagingDashboardContainer(state) {
      state.id =
        state.id === DisplayMessagingDashboardContainerId.HIDE
          ? DisplayMessagingDashboardContainerId.DISPLAY
          : DisplayMessagingDashboardContainerId.HIDE;
      state.value = updateValue(state.id);
    }
  }
});

export const {
  changeDisplayMessagingDashboardContainer,
  resetDisplayMessagingDashboardContainer,
  toggleDisplayMessagingDashboardContainer
} = displayMessagingDashboardContainerSlice.actions;

export default displayMessagingDashboardContainerSlice.reducer;
