import { createSlice } from '@reduxjs/toolkit';

export interface SelectMultiLocationStoreIdState {
  selectedMultiLocationStoreId: number[];
}

const initialState: SelectMultiLocationStoreIdState = {
  selectedMultiLocationStoreId: []
};

const selectMultiLocationStoreIdSlice = createSlice({
  name: 'selectMultiLocationStoreId',
  initialState,
  reducers: {
    selectMultiLocationStore: (state, action) => {
      const { id } = action.payload;
      state.selectedMultiLocationStoreId.push(id);
    },
    deselectMultiLocationStore: (state, action) => {
      const { id } = action.payload;
      const index = state.selectedMultiLocationStoreId.indexOf(id);
      if (index !== -1) {
        state.selectedMultiLocationStoreId.splice(index, 1);
      }
    },
    clearSelectedMultiLocationStore: (state) => {
      state.selectedMultiLocationStoreId = [];
    }
  }
});

export const {
  selectMultiLocationStore,
  clearSelectedMultiLocationStore,
  deselectMultiLocationStore
} = selectMultiLocationStoreIdSlice.actions;

export default selectMultiLocationStoreIdSlice.reducer;
