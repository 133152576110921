// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface MarketingCampaignsTabsState {
  // marketingCampaignsTab is a value of number type (1-4) and a label of sting type ('automated-campaigns', 'create-a-campaign', 'initialized-campaigns')
  id: 0 | 1 | 2;
  value: number;
  label: 'automated-campaigns' | 'create-a-campaign' | 'initialized-campaigns';
}

const initialState: MarketingCampaignsTabsState = {
  id: 0,
  value: 1,
  label: 'automated-campaigns'
};

const marketingCampaignsTabSlice = createSlice({
  name: 'marketingCampaignsTab',
  initialState,
  reducers: {
    changeMarketingCampaignsTabs: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.label = 'automated-campaigns';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.label = 'create-a-campaign';
      } else if (action.payload === 3) {
        state.id = 2;
        state.value = 3;
        state.label = 'initialized-campaigns';
      }
      state.value = action.payload;
    }
  }
});

// export the marketingCampaignsTab reducer as an action creator
// Redux toolkit automatically generates action creators for
// each reducer function passed into the reducers object
export const { changeMarketingCampaignsTabs } =
  marketingCampaignsTabSlice.actions;

// exporting the marketingCampaignsTabSlice.reducer function processes the actions
// passed into the redux store, and returns an updated version of the state
// each slice gets its own reducer function that is responsible for updating
// the state for that part of the data
export default marketingCampaignsTabSlice.reducer;
