import { SplashScreen } from '@/components/_shared/layouts/splash-screen';
import { AuthConsumer, AuthProvider } from '@/lib/contexts/auth-context';
import { DarkModeConsumer, DarkModeProvider } from '@/lib/contexts/dark-mode-context';
import { gtmConfig } from '@/lib/gtm/config';
import { gtm } from '@/lib/gtm/gtm';
import { store } from '@/lib/state/store';
import '@/lib/styles/global.css';
import type { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { flare } from '@flareapp/flare-client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import nProgress from 'nprogress';
import { FC, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';
import { createTheme } from '@/lib/theme';
import { createEmotionCache } from '@/lib/utils/create-emotion-cache';
import { FlareErrorBoundary } from '@flareapp/flare-react';
import ErrorBoundary from '@/components/_shared/error-boundary/Error';
import '@/lib/i18n/i18n';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import axios from 'axios';
import { UserbackProvider } from '@userback/react';
import Maintenance from '@/pages/maintenance';
import { Analytics } from '@vercel/analytics/react';
type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};
Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);
const clientSideEmotionCache = createEmotionCache();
flare.light(process.env.NEXT_PUBLIC_FLARE);
const App: FC<React.PropsWithChildren<EnhancedAppProps>> = props => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps
  } = props;
  const getLayout = Component.getLayout ?? (page => page);
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://rmdevs.com';
  //set axios headers to application/json
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  // TODO: add maintenance mode when backend is building
  const maintenance: boolean | undefined = false;
  if (maintenance) {
    return <Maintenance />;
  }
  return <FlareErrorBoundary data-sentry-element="FlareErrorBoundary" data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <CacheProvider value={emotionCache} data-sentry-element="CacheProvider" data-sentry-source-file="_app.tsx">
        <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
          <title>RipeMetrics</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        </Head>

        <ReduxProvider store={store} data-sentry-element="ReduxProvider" data-sentry-source-file="_app.tsx">
          <LocalizationProvider dateAdapter={AdapterDateFns} data-sentry-element="LocalizationProvider" data-sentry-source-file="_app.tsx">
            <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="_app.tsx">
              <AuthConsumer data-sentry-element="AuthConsumer" data-sentry-source-file="_app.tsx">
                {auth => <DarkModeProvider>
                    <DarkModeConsumer>
                      {({
                    darkMode
                  }) => <ThemeProvider theme={createTheme({
                    responsiveFontSizes: darkMode.responsiveFontSizes,
                    mode: darkMode.theme
                  })}>
                          <CssBaseline />
                          <Toaster position="top-right" reverseOrder={true} />

                          {!auth.isInitialized ? <SplashScreen /> : <UserbackProvider token={(process.env.NEXT_PUBLIC_USERBACK_TOKEN as string)}
                    //   options={{
                    //     name: auth.user?.name ?? '',
                    //     email: auth.user?.email ?? ''
                    //   }}
                    >
                              {getLayout(<ErrorBoundary>
                                  <Component {...pageProps} />
                                </ErrorBoundary>)}
                            </UserbackProvider>}
                        </ThemeProvider>}
                    </DarkModeConsumer>
                  </DarkModeProvider>}
              </AuthConsumer>
            </AuthProvider>
          </LocalizationProvider>
        </ReduxProvider>
        <Analytics data-sentry-element="Analytics" data-sentry-source-file="_app.tsx" />
      </CacheProvider>
    </FlareErrorBoundary>;
};
export default App;