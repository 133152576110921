import { createSlice } from '@reduxjs/toolkit';

export interface ShoppingTrendsSplineSelectorState {
  id: 0 | 1;
  value: number;
  status: 'sales' | 'purchases';
}

const initialState: ShoppingTrendsSplineSelectorState = {
  id: 0,
  value: 1,
  status: 'sales'
};

const shoppingTrendsSplineSelectorSlice = createSlice({
  name: 'shoppingTrendsSplineSelector',
  initialState,
  reducers: {
    changeShoppingTrendsSplineSelector: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.status = 'sales';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.status = 'purchases';
      }
      state.value = action.payload;
    }
  }
});

export const { changeShoppingTrendsSplineSelector } =
  shoppingTrendsSplineSelectorSlice.actions;

export default shoppingTrendsSplineSelectorSlice.reducer;
