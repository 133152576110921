import { CommunicationVolumeVolumeSplineData } from '@/lib/types/communication-volume';
import { createSlice } from '@reduxjs/toolkit';

export interface selectCommunicationVolumeSplineDataState {
  id: number | null;
  splineData: CommunicationVolumeVolumeSplineData | null;
  name: string;
}

const initialState: selectCommunicationVolumeSplineDataState = {
  id: null,
  splineData: null,
  name: ''
};

const selectCommunicationVolumeSplineDataSlice = createSlice({
  name: 'selectCommunicationVolumeSplineData',
  initialState,
  reducers: {
    selectCommunicationVolumeSplineData: (state, action) => {
      const { id, splineData, name } = action.payload;
      state.id = id;
      state.splineData = splineData;
      state.name = name;
    },
    deselectCommunicationVolumeSplineData: (state, action) => {
      const { id, splineData, name } = action.payload;
      if (state.id === id) {
        state.id = initialState.id;
        state.splineData = initialState.splineData;
        state.name = initialState.name;
      } else {
        state.id = id;
        state.splineData = splineData;
        state.name = name;
      }
    },
    clearSelectedCommunicationVolumeSplineData: (state) => {
      state.id = initialState.id;
      state.splineData = initialState.splineData;
      state.name = initialState.name;
    }
  }
});

export const {
  selectCommunicationVolumeSplineData,
  clearSelectedCommunicationVolumeSplineData,
  deselectCommunicationVolumeSplineData
} = selectCommunicationVolumeSplineDataSlice.actions;

export default selectCommunicationVolumeSplineDataSlice.reducer;
