import { ImageAttachedType } from '@/lib/state/slices/communication-slice';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface CampaignSelectorState {
  id: 0 | 1 | 2;
  value: number;
  status: 'unscheduled' | 'pending' | 'sent';
  imageAttached: ImageAttachedType | null;
  superInteractionImageAttached: ImageAttachedType | null;
}

const initialState: CampaignSelectorState = {
  id: 2,
  value: 3,
  status: 'sent',
  imageAttached: null,
  superInteractionImageAttached: null
};

const campaignsSelectorSlice = createSlice({
  name: 'campaignsSelector',
  initialState,
  reducers: {
    changeCampaignSelector: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.status = 'unscheduled';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.status = 'pending';
      } else if (action.payload === 3) {
        state.id = 2;
        state.value = 3;
        state.status = 'sent';
      }

      state.value = action.payload;
    },
    setCampaignImageAttached: (
      state,
      action: PayloadAction<ImageAttachedType | null>
    ) => {
      state.imageAttached = action.payload;
    },
    resetCampaignImageAttached: (state) => {
      state.imageAttached = null;
    },
    setSuperInteractionImageAttached: (
      state,
      action: PayloadAction<ImageAttachedType | null>
    ) => {
      state.superInteractionImageAttached = action.payload;
    },
    resetSuperInteractionImageAttached: (state) => {
      state.superInteractionImageAttached = null;
    }
  }
});

export const {
  changeCampaignSelector,
  setCampaignImageAttached,
  resetCampaignImageAttached,
  setSuperInteractionImageAttached,
  resetSuperInteractionImageAttached
} = campaignsSelectorSlice.actions;

export default campaignsSelectorSlice.reducer;
