import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotificationItem {
  id: number;
  title: string;
  data: string;
  begin_at: string;
  end_at: string;
  customer_store_id: number | null;
  customer_name: string | null;
}

interface NotificationsState {
  item: NotificationItem | null;
  isVisible: boolean;
}

const initialState: NotificationsState = {
  item: null,
  isVisible: false
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<NotificationItem>) => {
      state.item = action.payload;
      state.isVisible = true;
    },
    clearNotification: (state) => {
      state.item = null;
      state.isVisible = false;
    }
  }
});

export const { setNotification, clearNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
