// old communication center page, remove after BI overview page is complete
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { AppThunk } from '@/lib/state/store';
import type {
  Contact,
  Message,
  Thread
} from '../../types/communication-center';
import { objFromArray } from '../../utils/obj-from-array';

interface CommunicationCenterState {
  activeThreadId?: string;
  contacts: {
    byId: Record<string, Contact>;
    allIds: string[];
  };
  threads: {
    byId: Record<string, Thread>;
    allIds: string[];
  };
}

const initialState: CommunicationCenterState = {
  activeThreadId: undefined,
  contacts: {
    byId: {},
    allIds: []
  },
  threads: {
    byId: {},
    allIds: []
  }
};

const slice = createSlice({
  name: 'communicationCenter',
  initialState,
  reducers: {
    getContacts(
      state: CommunicationCenterState,
      action: PayloadAction<Contact[]>
    ): void {
      const contacts = action.payload;
      //@ts-ignore
      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },
    getThreads(
      state: CommunicationCenterState,
      action: PayloadAction<Thread[]>
    ): void {
      const threads = action.payload;
      //@ts-ignore
      state.threads.byId = objFromArray(threads);
      state.threads.allIds = Object.keys(state.threads.byId);
    },
    getThread(
      state: CommunicationCenterState,
      action: PayloadAction<Thread | null>
    ): void {
      const thread = action.payload;

      if (thread) {
        state.threads.byId[thread.id!] = thread;

        if (!state.threads.allIds.includes(thread.id!)) {
          state.threads.allIds.unshift(thread.id!);
        }
      }
    },
    markThreadAsSeen(
      state: CommunicationCenterState,
      action: PayloadAction<string>
    ): void {
      const threadId = action.payload;
      const thread = state.threads.byId[threadId];

      if (thread) {
        thread.unreadCount = 0;
      }
    },
    setActiveThread(
      state: CommunicationCenterState,
      action: PayloadAction<string | undefined>
    ): void {
      state.activeThreadId = action.payload;
    },
    addMessage(
      state: CommunicationCenterState,
      action: PayloadAction<{ message: Message; threadId: string }>
    ): void {
      const { threadId, message } = action.payload;
      const thread = state.threads.byId[threadId];

      if (thread) {
        thread.messages.push(message);
      }
    }
  }
});

export const { reducer } = slice;

export const setActiveThread =
  (threadId?: string): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.setActiveThread(threadId));
  };

export const addMessage = null;
