import { Button } from '@mui/material';
import React, { Component } from 'react';
import { reportReactError } from '@flareapp/flare-react';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
    reportReactError(error, errorInfo);
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <h2 className="py-4 text-lg font-proximaNov">
            Network Error Reload Page
          </h2>
          <Button
            type="button"
            sx={{
              backgroundColor: 'orange',
              color: 'white',

              '&:hover': {
                border: '1px solid #f57c00',
                backgroundColor: 'white',
                color: '#f57c00'
              }
            }}
            className="font-proximaNova"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </Button>
        </div>
      );
    }

    // Return children components in case of no erro

    return this.props.children;
  }
}

export default ErrorBoundary;
