import type { FC } from 'react';
import { Box } from '@mui/material';
import { IconLogo } from '@/assets/logos/icon-logo';
import { keyframes } from '@emotion/react';
const bounce1 = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 1px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;
const bounce3 = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 9px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;
export const SplashScreen: FC<React.PropsWithChildren<unknown>> = () => <Box sx={{
  alignItems: 'center',
  backgroundColor: 'neutral.100',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center',
  left: 0,
  p: 3,
  position: 'fixed',
  top: 0,
  width: '100vw',
  zIndex: 2000
}} data-sentry-element="Box" data-sentry-component="SplashScreen" data-sentry-source-file="splash-screen.tsx">
    <IconLogo sx={{
    height: 240,
    width: 240,
    '& path:nth-child(1)': {
      animation: `${bounce1} 1s ease-in-out infinite`
    },
    '& path:nth-child(2)': {
      animation: `${bounce3} 1s ease-in-out infinite`
    }
  }} data-sentry-element="IconLogo" data-sentry-source-file="splash-screen.tsx" />
  </Box>;