// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface MarketingGroupsTabsState {
  // marketingGroupsTab is a value of number type (1-4) and a label of sting type ('automated-groups', 'create-a-group', 'reconsider')
  id: 0 | 1 | 2;
  value: number;
  label: 'automated-groups' | 'create-a-group';
}

const initialState: MarketingGroupsTabsState = {
  id: 0,
  value: 1,
  label: 'automated-groups'
};

const marketingGroupsTabSlice = createSlice({
  name: 'marketingGroupsTab',
  initialState,
  reducers: {
    changeMarketingGroupsTabs: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.label = 'automated-groups';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.label = 'create-a-group';
      }
      state.value = action.payload;
    }
  }
});

// export the marketingGroupsTab reducer as an action creator
// Redux toolkit automatically generates action creators for
// each reducer function passed into the reducers object
export const { changeMarketingGroupsTabs } = marketingGroupsTabSlice.actions;

// exporting the marketingGroupsTabSlice.reducer function processes the actions
// passed into the redux store, and returns an updated version of the state
// each slice gets its own reducer function that is responsible for updating
// the state for that part of the data
export default marketingGroupsTabSlice.reducer;
